import { gql, useQuery } from "@apollo/client";
import { Avatar, Box, Button, Card, Rating, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, CustomDateRangePicker } from "../../../components";
import CustomFilterPanelForTable from "../../../components/customFilterPanelForTable";
import { getInitials } from "../../../helpers/name";
import { selectCompleteDate } from "../../../redux/layout";
import { isoDateRenderer, priceRenderer } from "../../../tableUtils";
import DeleteAction from "./DeleteAction";
import EditNotesAction from "./EditNotesAction";
import RescheduleAction from "./RescheduleAction";
import SetInvoicedAction from "../../packages/components/SetInvoicedAction";
import { WhatsappActionFactory } from "../../../components/SendWhatsappAction";
import SendMailAction from "./SendMailAction";

export const ResultList = (props) => {
	const navigate = useNavigate();
	const query = gql`
		query listPackagesByWorkDate($dateFrom: String!, $dateTo: String!) {
			pkgs: listPackagesByWorkDate(dateFrom: $dateFrom, dateTo: $dateTo)
		}
	`;

	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const { data: { pkgs = [] } = {}, loading, refetch: runQuery } = useQuery(query, { variables: { dateFrom: dateFrom, dateTo: dateTo }, fetchPolicy: "network-only" });
	// const rows = data?.getAllTasks || [];
	const rows = pkgs;

	const avatarRenderer = (user) => {
		return (
			<Box sx={{ alignItems: "center", display: "flex" }}>
				<Avatar src={user?.profilePicture} sx={{ mr: 2 }}>
					{getInitials(`${user?.firstName} ${user?.lastName}`)}
				</Avatar>
				<Typography color="textPrimary" variant="body1">
					{user?.firstName} {user?.lastName}
				</Typography>
			</Box>
		);
	};
	const renderDetailsButton = (row) => {
		return (
			<Button onClick={() => navigate(`/package/${row.id}`)} variant="contained" color="primary">
				Ver detalles
			</Button>
		);
	};

	const qualificationView = (row) => <>{row.qualification?.stars ? <Rating value={row.qualification?.stars} readOnly /> : null}</>;

	const userIdsMapper = (selectedRows) => selectedRows.map((row) => row.ownerId);
	const providerIdsMapper = (selectedRows) => selectedRows.map((row) => row.providerId).filter((id) => !!id);

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Reporte de paquetes por fecha de trabajo
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<Card {...props}>
				<Box sx={{ minWidth: 1050 }}>
					<CustomDataGrid
						filterPanel={CustomFilterPanelForTable}
						reload={runQuery}
						loading={loading}
						actions={[
							SendMailAction,
							WhatsappActionFactory(userIdsMapper, { tooltip: "Enviar Whatsapp al Usuario" }),
							WhatsappActionFactory(providerIdsMapper, { tooltip: "Enviar Whatsapp al Experto" }),
							DeleteAction,
							EditNotesAction,
							RescheduleAction,
							SetInvoicedAction,
						]}
						hideFooterSelectedRowCount
						checkboxSelection
						label=""
						rows={rows}
						columns={[
							{ id: "id", label: "ID", type: "number" },
							{ id: "externalId", label: "ID externo" },
							{ id: "createdAt", label: "Fecha de creacion", ...isoDateRenderer("createdAt") },
							{ id: "title", label: "Titulo" },
							{ id: "acquisitionMethod.value", label: "Metodo de adquisicion" }, //check
							{ id: "ownerId", label: "Id de usuario" },
							{ id: "owner", label: "Usuario", customRender: (row) => avatarRenderer(row.owner), customValue: (row) => `${row.owner?.firstName} ${row.owner?.lastName}` },
							{ id: "providerId", label: "Id de experto" },
							{ id: "provider", label: "Experto", customRender: (row) => avatarRenderer(row.provider), customValue: (row) => `${row.provider?.firstName} ${row.provider?.lastName}` },
							{ id: "status", label: "Estado" },
							{ id: "category/subcategory", label: "Categoria", customValue: ({ subcategory: s, category: c }) => (s ? `${c} / ${s}` : c) },
							{ id: "views", label: "Vistas" },
							{ id: "applicationsCount", label: "Aplicaciones" },
							{ id: "conversationsCount", label: "Conversaciones" },
							{ id: "address.inline", label: "Direccion" },
							{ id: "address.apartment", label: "Piso" },
							{ id: "address.locality", label: "Localidad" },
							{ id: "workDate", label: "Fecha de cooridnacion", ...isoDateRenderer("workDate") },
							{ id: "price", label: "Importe a abonar al experto", ...priceRenderer("price") },
							{ id: "totalPrice", label: "Importe cobrado al usuario", ...priceRenderer("totalPrice") },
							{ id: "finnishAt", label: "Fecha de finalizacion", ...isoDateRenderer("finnishDate") },
							{ id: "qualification.stars", label: "Calificacion", customValue: (row) => get(row, "qualification.stars"), customRender: qualificationView },
							{ id: "deletedAt", label: "Fecha de eliminacion", ...isoDateRenderer("deletedAt") },
							{ id: "adminNotes", label: "Notas de administrador" },
							{ id: "invoicedAt", label: "Fecha de facturacion", ...isoDateRenderer("invoicedAt") },
							{ id: "branch", label: "Sucursal", customValue: (row) => get(row, "branch") },
							{ id: "seller", label: "Vendedor" },
							{ id: "dni", label: "DNI" },
							{ id: "email", label: "Email", customValue: (row) => row?.owner?.email },
							{ id: "open", label: "Detalles", customRender: renderDetailsButton },
						]}
					/>
				</Box>
			</Card>
		</>
	);
};
