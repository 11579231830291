import { gql } from "@apollo/client"

export const getAllAcquisitionMethods = gql`query {
    methods: getAllAcquisitionMethods {
        id
        title: flatTitle
        disabled
        createdAt
    }
}`

export const getAllAcquisitionMethodsList = gql`query {
    methods: getAllAcquisitionMethodsList {
        id
        value
        # title: flatTitle
        revenueRate
        title {
            es
            en
            pt
        }
        usersCount
        createdTasksCount
        hiredTasksCount
        defaultOrderField
        disabled
        createdAt
    }
}`


export const getAcquisitionMethod = gql`query get($id: Int!) {
    method: getAcquisitionMethod(id: $id) {
        id
        value
        title {
            id
            es
            en
            pt
        }
        revenueRate
        disabled
        createdAt
        defaultOrderField
    }
}`

export const saveAcquisitionMethods = gql`mutation save($id: Int, $title: TranslationInput, $disabled: Boolean, $value: String, $revenueRate: Float, $defaultOrderField: String) {
    saveAcquisitionMethod(id: $id, title: $title, disabled: $disabled, value: $value, revenueRate: $revenueRate, defaultOrderField: $defaultOrderField) {
        id
        value
        revenueRate
        title {
            id
            es
            en
            pt
        }
        defaultOrderField
        disabled
        createdAt
    }
}`

export const deleteAcquisitionMethods = gql`mutation delete($ids: [Int]!) {
    deleteAcquisitionMethods(ids: $ids)
}`