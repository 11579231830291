import { gql, useQuery } from "@apollo/client";
import FormSelect from "./Select";
import { DEFAULT_ORDER_FIELDS_LABEL } from "../../helpers/constants";

const GQLGetAcquisitionMethodsForSelect = gql`
	query listDeaultOrderFieldAcquisitionMethods {
		defaultOrderFieldAcquisitionMethods: listDeaultOrderFieldAcquisitionMethods 
	}
`;

export default function FormAcquisitionMethodDefaultOrderFieldSelect(props) {
	const { data: { defaultOrderFieldAcquisitionMethods = [] } = {} } = useQuery(GQLGetAcquisitionMethodsForSelect, { fetchPolicy: "network-only" });
	return <FormSelect {...props} options={defaultOrderFieldAcquisitionMethods.map((value) => ({ value: value, label: DEFAULT_ORDER_FIELDS_LABEL[value] }))} />;
}
