import { Button, Checkbox } from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AreaEditor from "./components/AreaEditor";
import CustomMarker from "./components/CustomMarker";

const { PROVIDER_AREAS, TASKS_AREAS } = {"PROVIDER_AREAS":[{"lat":-34.64409580022911,"lon":-58.3823010595553,"radius":79.8879508411552},{"lat":-32.97138298260975,"lon":-60.6797445901936,"radius":31.130178983573593},{"lat":-31.434585537641794,"lon":-64.1832538744312,"radius":64.67049415420419},{"lat":-38.03417544762842,"lon":-57.56490991694191,"radius":21.897255681722065},{"lat":-32.93719794659348,"lon":-68.83551810806844,"radius":47.95461545623118},{"lat":-24.789521769794877,"lon":-65.43799613541219,"radius":67.65910120749825}],"TASKS_AREAS":[{"lat":-34.427167,"lon":-58.872887,"radius":8.308},{"lat":-34.489951,"lon":-59.005376,"radius":9.012},{"lat":-34.44806,"lon":-58.977911,"radius":9.01},{"lat":-34.619365945720844,"lon":-58.44310335387067,"radius":50},{"lat":-34.92149454519087,"lon":-57.95442640315118,"radius":20},{"lat":-32.9856423993162,"lon":-60.676919947015165,"radius":30.905856248082},{"lat":-31.418961039247137,"lon":-64.19286674390251,"radius":53.829735472563705},{"lat":-38.02657091840373,"lon":-57.565218686078005,"radius":20.216939986301647},{"lat":-32.93681385798763,"lon":-68.83972926004085,"radius":40.79262280455003},{"lat":-24.79168274782822,"lon":-65.44074271744344,"radius":54.41114342129077}]};

const INITIAL_AREAS = [...TASKS_AREAS.map((a) => ({ ...a, type: "task", enabled: true })), ...PROVIDER_AREAS.map((a) => ({ ...a, type: "provider", enabled: true }))];

export default function AllowedAreas() {
	const mapRef = React.useRef(null);
	const [providerEnabled, setProviderEnabled] = useState(true);
	const [taskEnabled, setTaskEnabled] = useState(true);
	const dispatch = useDispatch();
	const [mapCenter, setMapCenter] = useState({ lat: -34.60198192521708, lng: -58.38241811930042 });
	const [areas, setAreas] = useState(INITIAL_AREAS);
	const { isLoaded } = useJsApiLoader({
		id: "1ea02683e0a5f5da",
		googleMapsApiKey: "AIzaSyBCEfp-oDemVsye7os3SfNlGH70OT6CUeA",
		libraries: ["visualization"],
	});

	return (
		<>
			<div>
				<h1>Allowed Areas</h1>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
					}}>
					<div>
						<Checkbox color="primary" checked={providerEnabled} onChange={() => setProviderEnabled((pe) => !pe)} />
						<label>Provider Areas</label>
						<Checkbox color="primary" checked={taskEnabled} onChange={() => setTaskEnabled((te) => !te)} />
						<label>Task Areas</label>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							// margin: 20,
							gap: 10,
						}}>
						{/* <Button variant="contained" color="primary" onClick={() => setAreas((areas) => [...areas, { enabled: true, type: "task" }])}>
							Add Area
						</Button> */}
						<Button
							variant="contained"
							color="success"
							onClick={() => {
								const areasToClipboard = {
									PROVIDER_AREAS: areas.filter((a) => a.type === "provider").map((a) => ({ lat: a.lat, lon: a.lon, radius: a.radius })),
									TASKS_AREAS: areas.filter((a) => a.type === "task").map((a) => ({ lat: a.lat, lon: a.lon, radius: a.radius })),
								};
								navigator.clipboard.writeText(JSON.stringify(areasToClipboard));
							}}>
							Copiar areas al portapapeles
						</Button>
					</div>
				</div>
				<div
					style={{
						// warning box message
						border: "2px solid #FFA50050",
						borderRadius: 10,
						padding: 10,
						backgroundColor: "#FFFF0080",
						color: "#0f0f0f",
						fontWeight: "bold",
						fontSize: 16,
						margin: "10px 0",
					}}>
					Recordá que al terminar de editar las areas habilitadas, debes copiarlas al portapapeles para luego configurarlas del entorno.
				</div>
				{isLoaded && (
					<GoogleMap
						ref={mapRef}
						onClick={(e) => {
							console.log(e.latLng.toJSON());
							//copy to clipboard
							// navigator.clipboard.writeText(JSON.stringify(e.latLng.toJSON()));
							// notify user
							// dispatch(setSnackBar({ text: "Coordenadas copiadas al portapapeles.", severity: "success", duration: 2000 }));

							setAreas((areas) => [...areas, { enabled: true, lat: e.latLng.lat(), lon: e.latLng.lng(), radius: 10, type: "task" }]);
						}}
						options={{
							mapId: "1ea02683e0a5f5da",
						}}
						mapContainerStyle={{
							marginTop: "10px",
							width: "100%",
							height: "500px",
							borderRadius: 10,
						}}
						center={mapCenter}
						zoom={10}>
						{areas
							.filter((a) => a.enabled && ((a.type === "provider" && providerEnabled) || (a.type === "task" && taskEnabled)))
							.map((area) => (
								<CustomMarker
									area={area}
									onChange={({ lat, lng }, radius) => {
										console.log("EDITED RADIUS =>", radius);
										const newArea = { ...area, lat, lon: lng, radius };
										// setMapCenter({ lat, lng });
										setAreas((areas) => {
											const newAreas = [...areas];
											const editedIndex = areas.findIndex((a) => a.lat === area.lat && a.lon === area.lon && a.type === area.type);
											newAreas[editedIndex] = newArea;
											return newAreas;
										});
									}}
								/>
							))}
					</GoogleMap>
				)}
			</div>

			<div
				style={{
					// make 2 columns
					display: "flex",
					// flexDirection: "row",
					gap: 10,
					flexWrap: "wrap",
					justifyContent: "space-between",
					// make elements use all available space
					width: "100%",
				}}>
				{areas.map((area, index) => (
					<AreaEditor
						area={area}
						deleteArea={() => setAreas((areas) => areas.filter((a, i) => i !== index))}
						onChange={(newArea) => {
							setAreas((areas) => {
								const newAreas = [...areas];
								newAreas[index] = newArea;
								return newAreas;
							});
						}}
					/>
				))}
			</div>
		</>
	);
}
